import * as React from 'react'
import { useForm, SubmitHandler } from "react-hook-form"
import { yupResolver } from "@hookform/resolvers/yup"
import { object, string, InferType, array, bool } from 'yup'
import { Box, Button, Chip, CircularProgress, Divider, Fade, FormControl, Grid, InputLabel, MenuItem, Select, TextField, Typography } from '@mui/material';
import axios from 'axios'
import { useNavigate } from 'react-router-dom'
import { showAlert } from '../../utils/showAlert'

import pixar from "../../images/pixar.jpg"
import manga from "../../images/manga.jpg"
// import retro from "../../images/retro.jpg"
import kid from "../../images/kid.jpg"
import { isTokenValid } from '../../utils/isTokenValid';

const MOBILE_MAX_WIDTH = 600
const MAX_WIDTH = 1300
const MOBILE_TITLE_BREAKPOINT = 12
const MOBILE_CONTENT_BREAKPOINT = 12
const DESKTOP_TITLE_BREAKPOINT = 5
const DESKTOP_CONTENT_BREAKPOINT = 7

const THEMES = {
  "dinosaurs": "🦖 Dinosaures",
  "princesses": "👸 Princesses",
  "robots": "🤖 Robots",
  "pirates": "🏴‍☠️ Pirates",
  "superheroes": "🦸‍♂️ Super-Héros",
  "fairies": "🧚 Fées",
  "space adventures": "🚀 Aventures Spatiales",
  "magical creatures": "🦄 Créatures Magiques",
  "underwater worlds": "🌊 Mondes Sous-Marins",
  "race cars": "🏎️ Voitures de Course",
  "trains": "🚂 Trains",
  "unicorns": "🦄 Licornes",
  "castles": "🏰 Châteaux",
  "jungle animals": "🐒 Animaux de la Jungle",
  "farm animals": "🐄 Animaux de la Ferme",
  "knights": "🛡️ Chevaliers",
  "mermaids": "🧜‍♀️ Sirènes",
  "space": "🌌 Espace",
  "magic": "✨ Magie",
  "dragons": "🐉 Dragons",
  "zombies": "🧟 Zombies",
  "ghosts": "👻 Fantômes",
  "wizards": "🧙 Sorciers",
  "fairy tales": "📖 Contes de Fées",
  "time travel": "⏳ Voyages dans le Temps",
  "sports": "🏅 Sports",
  "detectives": "🕵️ Détectives",
  "treasure hunts": "💰 Chasses au Trésor",
  "secret agents": "🕶️ Agents Secrets",
  "monsters": "👹 Monstres",
  "robots and aliens": "👽 Robots et Extraterrestres",
  "talking animals": "🐾 Animaux Parlants",
  "enchanted forests": "🌲 Forêts Enchantées",
  "sky adventures": "☁️ Aventures dans le Ciel",
  "ballet": "🩰 Ballet",
  "spies": "🕵️ Espions",
  "circus": "🎪 Cirque",
  "sea creatures": "🐙 Créatures Marines",
  "construction vehicles": "🚜 Véhicules de Construction",
  "gardening": "🌻 Jardinage",
  "camping": "🏕️ Camping",
  "arctic exploration": "❄️ Exploration de l'Arctique",
  "desert exploration": "🏜️ Exploration du Désert",
  "rockets and astronauts": "🚀 Fusées et Astronautes",
  "superpowers": "💥 Super Pouvoirs",
  "holiday adventures": "🏖️ Aventures de Vacances",
  "mysteries": "🔍 Mystères",
  "science experiments": "🔬 Expériences Scientifiques",
  "space missions": "🛰️ Missions Spatiales",
  "heroes and villains": "🦹 Héros et Méchants",
  "storybook characters": "🧙 Personnages de Contes",
  "dolls": "🪆 Poupées",
  "puppies and kittens": "🐾 Chiots et Chatons",
  "mythical creatures": "🧌 Créatures Mythiques",
  "Wild West adventures": "🤠 Aventures dans le Far West",
  "life in the future": "🔮 Vie dans le Futur",
  "animal kingdoms": "🦁 Royaumes des Animaux",
  "high-seas adventures": "🌊 Aventures en Haute Mer",
  "medieval times": "⚔️ Temps Médiévaux",
  "mysterious islands": "🏝️ Îles Mystérieuses",
  "science fiction": "👽 Science-Fiction",
  "sports championships": "🏆 Championnats Sportifs",
  "secret islands": "🏝️ Îles Secrètes",
  "wilderness survival": "🌲 Survie en Pleine Nature",
  "inventors": "🔧 Inventeurs"
}


const STYLES = [
  { image: pixar, name: "Pixar", id: 'pixar' },
  { image: manga, name: "Manga", id: 'manga' },
  // { image: retro, name: "Retro", id: 'retro' },
  { image: kid, name: "Enfant", id: 'kid' },
]

const BOOK_CREATION_URL = '/book/create'

const inputsSchema = object({
  name: string().required('Le prénom est requis.').min(2, 'Le prénom doit faire au moins 2 caractères.'),
  age: string().required("L'âge est requis."),
  gender: string().required('Le sexe est requis.'),
  skinColor: string().required('La couleur de peau est requise.'),
  eyesColor: string().required('La couleur des yeux est requise.'),
  hairColor: string().required('La couleur des cheveux est requise.'),
  hairStyle: string(),
  glasses: bool().required(),
  personality: string(),
  favoriteColor: string(),
  selectedThemes: array().of(string())
    .max(3, 'Sélectionnez 3 thèmes ou moins.')
    .min(1, 'Sélectionnez au moins un thème.'),
  style: string().required("Sélectionnez un style d'illustration"),
})

type Inputs = InferType<typeof inputsSchema>

export default function CreateBook() {
  const {
    register,
    handleSubmit,
    formState: { errors },
    watch,
    setValue,
    trigger,
    getValues,
  } = useForm<Inputs>({
    defaultValues: {
      selectedThemes: [],
      glasses: false
    }, 
    mode: 'onChange',
    resolver: yupResolver(inputsSchema),
  })
  const navigate = useNavigate()

  const [isValid, setIsValid] = React.useState(true)

  React.useEffect(() => {
    isTokenValid().then((isValid) => setIsValid(isValid))
  }, [navigate])

  React.useEffect(() => {
    if (!isValid) navigate('/')
  }, [isValid, navigate])

  const [isLoading, setIsLoading] = React.useState(false)
  const [isAlertShown, setIsAlertShown] = React.useState(false)
  const hasErrors = Object.entries(errors).length > 0

  const onSubmit: SubmitHandler<Inputs> = (data) => {
    const authToken = localStorage.getItem('authToken')
    setIsLoading(true)
    axios
      .post(BOOK_CREATION_URL, {
        ...data,
      }, {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${authToken}`
        }
      })
      .then((response) => {
        console.log('response')
        console.log(response)
        navigate("/books/" + response.data.id)
        setIsLoading(false)
      })
      .catch((error) => {
        console.log('error')
        console.log(error)
        if (error?.response?.data && error?.response?.data.includes("Wait")) {
          setIsAlertShown(true)
        }
      })
  }

  React.useEffect(() => {
    if (isAlertShown) {
      setTimeout(() => setIsAlertShown(false), 5000)
    }
  }, [isAlertShown])

  const childDescriptionFields = [
    'name',
    'age',
    'gender',
    'skinColor',
    'eyesColor',
    'hairColor',
    'hairStyle',
    'glasses',
    'personality',
    'favoriteColor',
  ]
  const childDescriptionFieldsErrors = childDescriptionFields.some(e => Object.keys(errors).includes(e))

  watch("selectedThemes")
  watch("style")
  const gender = watch('gender')

  const handleClickTheme = (theme: string) => {
    const selectedThemes = getValues("selectedThemes")
    if (!selectedThemes) return
    const themeIndex = selectedThemes.indexOf(theme)
    if (themeIndex === -1) {
      setValue("selectedThemes", [...selectedThemes, theme])
    } else {
      selectedThemes.splice(themeIndex, 1)
      setValue('selectedThemes', selectedThemes)
    }
    trigger()
  }

  const isThemeSelected = (theme: string) => {
    const selectedThemes = getValues("selectedThemes")
    if (!selectedThemes) return
    if (selectedThemes.indexOf(theme) === -1) {
      return false
    } else {
      return true
    }
  }

  const handleClickStyle = (style: string) => {
    setValue('style', style)
    trigger('style')
  }

  const isStyleSelected = (style: string) => {
    const selectedStyle = getValues("style")
    if (!selectedStyle) return true
    if (selectedStyle === style) return true
    return false
  }
  
  return (
    <>
      <Box
        display="flex"
        flexDirection="column"
        flexWrap="wrap"
        alignContent="center"
        paddingBottom="100px"
        marginTop="64px"
      >
        <Fade in={isAlertShown}>{
          showAlert("Vous ne pouvez créer que 3 histoire par jour.")
        }</Fade>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Grid
            container
            padding={{xs: "50px", md: "80px" }}
            spacing={{ xs: 4, md: 10 }}
            maxWidth={{ xs: `${MOBILE_MAX_WIDTH}px`, md: MAX_WIDTH }}
          >
            <Grid item xs={MOBILE_TITLE_BREAKPOINT} md={DESKTOP_TITLE_BREAKPOINT}>
              <Typography variant="h5" gutterBottom paddingBottom={2}>
                👶 Informations sur votre enfant
              </Typography>
              <Typography variant="body2" gutterBottom>
                Pour commencer la création de votre histoire personnalisée, donnez-nous quelques informations sur votre enfant. 
                Quel âge a-t-il ? Comment s’appelle-t-il ? Comment est-il physiquement ? Quels sont ses traits de caractère ? 
              </Typography>
              <Typography variant="body2" gutterBottom>
                Ces informations permettent à la magie d’opérer et de générer des histoires et des illustrations captivantes 
                dans lesquelles votre enfant pourra se projeter.
              </Typography>
              {childDescriptionFieldsErrors ?
                <Typography variant="body2" color="orange" gutterBottom>
                  {Object.keys(errors)
                    .filter(e => childDescriptionFields.includes(e))
                    .filter(e => !!errors[e].message)
                    .map(e => errors[e].message)
                    .join(' ')}
                </Typography>
                : <></>
              }
            </Grid>

            <Grid item xs={MOBILE_CONTENT_BREAKPOINT} md={DESKTOP_CONTENT_BREAKPOINT} display="flex" flexDirection="column" gap="20px">
              <Grid container spacing={{ xs: 2, md: 3 }}>
                <Grid item xs={12} md={6} display="flex" flexDirection="column">
                  <TextField 
                    id="name" 
                    size="small"
                    label="Prénom*"
                    {...register("name")}
                  />
                </Grid>

                <Grid item xs={12} md={6} display="flex" flexDirection="column">
                  <FormControl size="small">
                    <InputLabel id="age-select">Age*</InputLabel>
                    <Select
                      labelId="age-select"
                      id="age-select"
                      label="Age*"
                      defaultValue=""
                      {...register("age")}
                    >
                      <MenuItem value={'2'}>2 ans</MenuItem>
                      <MenuItem value={'3'}>3 ans</MenuItem>
                      <MenuItem value={'4'}>4 ans</MenuItem>
                      <MenuItem value={'5'}>5 ans</MenuItem>
                      <MenuItem value={'6'}>6 ans</MenuItem>
                      <MenuItem value={'7'}>7 ans</MenuItem>
                      <MenuItem value={'8'}>8 ans</MenuItem>
                      <MenuItem value={'9'}>9 ans</MenuItem>
                      <MenuItem value={'10'}>10 ans</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>

                <Grid item xs={12} md={6} display="flex" flexDirection="column">
                  <FormControl size="small">
                    <InputLabel id="gender-select">Sexe*</InputLabel>
                    <Select
                      labelId="gender-select"
                      id="gender-select"
                      label="Sexe*"
                      defaultValue=""
                      {...register("gender")}
                    >
                      <MenuItem value={'m'}>💁‍♂️ Garçon</MenuItem>
                      <MenuItem value={'f'}>💁‍♀️ Fille</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
                
                <Grid item xs={12} md={6} display="flex" flexDirection="column">
                  <FormControl size="small">
                    <InputLabel id="skin-color-select">Couleur de peau*</InputLabel>
                    <Select
                      labelId="skin-color-select"
                      id="skin-color-select"
                      label="Couleur de peau*"
                      defaultValue=""
                      {...register("skinColor")}
                    >
                      <MenuItem value={'white'}>{gender === 'f' ? '👧🏻' : '👦🏻'} Peau claire</MenuItem>
                      <MenuItem value={'tanned'}>{gender === 'f' ? '👧🏽' : '👦🏽'} Peau matte</MenuItem>
                      <MenuItem value={'matt'}>{gender === 'f' ? '👧🏾' : '👦🏾'} Peau métisse</MenuItem>
                      <MenuItem value={'black'}>{gender === 'f' ? '👧🏿' : '👦🏿'} Peau noire</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
                
                <Grid item xs={12} md={6} display="flex" flexDirection="column">
                  <FormControl size="small">
                    <InputLabel id="hair-color-select">Couleur des cheveux*</InputLabel>
                    <Select
                      labelId="hair-color-select"
                      id="hair-color-select"
                      label="Couleur des cheveu*"
                      defaultValue=""
                      {...register("hairColor")}
                    >
                      <MenuItem value={'blond'}>🐣 Blonds</MenuItem>
                      <MenuItem value={'red hair'}>🦁 Roux</MenuItem>
                      <MenuItem value={'chestnut'}>🐿️ Châtains</MenuItem>
                      <MenuItem value={'brown'}>🐻 Bruns</MenuItem>
                      <MenuItem value={'black'}>🐼 Noirs</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
                
                <Grid item xs={12} md={6} display="flex" flexDirection="column">
                  <FormControl size="small">
                    <InputLabel id="hair-style-select">Style de cheveux*</InputLabel>
                    <Select
                      labelId="hair-style-select"
                      id="hair-style-select"
                      label="Style de cheveux*"
                      defaultValue=""
                      {...register("hairStyle")}
                    >
                      <MenuItem value={'short and straight'}>💇‍♂️ Cheveux courts et raides</MenuItem>
                      <MenuItem value={'short and wavy'}>💇‍♂️ Cheveux courts et ondulés</MenuItem>
                      <MenuItem value={'long and curly'}>🦱 Cheveux longs et bouclés</MenuItem>
                      <MenuItem value={'long and wavy'}>🌊 Cheveux longs et ondulés</MenuItem>
                      <MenuItem value={'long and straight'}>💁‍♀️ Cheveux longs et lisses</MenuItem>
                      <MenuItem value={'bowl cut'}>✂️ Coupe au bol</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>

                <Grid item xs={12} md={6} display="flex" flexDirection="column">
                  <FormControl size="small">
                    <InputLabel id="eyes-color-select">Couleur des yeux*</InputLabel>
                    <Select
                      labelId="eyes-color-select"
                      id="eyes-color-select"
                      label="Couleur de yeux*"
                      defaultValue=""
                      {...register("eyesColor")}
                    >
                      <MenuItem value={'blue'}>💙 Yeux bleus</MenuItem>
                      <MenuItem value={'green'}>💚 Yeux verts</MenuItem>
                      <MenuItem value={'brown'}>🤎 Yeux marrons</MenuItem>
                      <MenuItem value={'black'}>🖤 Yeux noirs</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
                
                <Grid item xs={12} md={6} display="flex" flexDirection="column">
                  <FormControl size="small">
                    <InputLabel id="glasses-select">Lunettes</InputLabel>
                    <Select
                      labelId="glasses-select"
                      id="glasses-select"
                      label="Lunettes"
                      defaultValue='false'
                      {...register("glasses")}
                    >
                      <MenuItem value={'false'}>🤗 Pas de lunettes</MenuItem>
                      <MenuItem value={'true'}>🤓 Porte des lunettes</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>

                <Grid item xs={12} md={6} display="flex" flexDirection="column">
                  <FormControl size="small">
                    <InputLabel id="personality-select">Caractère</InputLabel>
                    <Select
                      labelId="personality-select"
                      id="personality-select"
                      label="Caractère"
                      defaultValue=""
                      {...register("personality")}
                    >
                      <MenuItem value={'dynamique'}>⚡ Dynamique</MenuItem>
                      <MenuItem value={'observateur'}>👀 Observateur</MenuItem>
                      <MenuItem value={'créatif'}>🎨 Créatif</MenuItem>
                      <MenuItem value={'persévérant'}>💪 Persévérant</MenuItem>
                      <MenuItem value={'affectueux'}>💖 Affectueux</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>

                <Grid item xs={12} md={6} display="flex" flexDirection="column">
                  <FormControl size="small">
                    <InputLabel id="favorite-color-select">Couleur préférée</InputLabel>
                    <Select
                      labelId="favorite-color-select"
                      id="favorite-color-select"
                      label="Couleur préférée"
                      defaultValue=""
                      {...register("favoriteColor")}
                    >
                      <MenuItem value={'bleu'}>🧢 Bleu</MenuItem>
                      <MenuItem value={'rouge'}>🚗 Rouge</MenuItem>
                      <MenuItem value={'vert'}>🌳 Vert</MenuItem>
                      <MenuItem value={'jaune'}>🌟 Jaune</MenuItem>
                      <MenuItem value={'rose'}>🌸 Rose</MenuItem>
                      <MenuItem value={'orange'}>🍊 Orange</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
              </Grid>

              <Divider />
            </Grid>

            <Grid item xs={MOBILE_TITLE_BREAKPOINT} md={DESKTOP_TITLE_BREAKPOINT}>
              <Typography variant="h5" gutterBottom paddingBottom={2}>
                🧸 Jeux et passions de votre enfant
              </Typography>
              <Typography variant="body2" gutterBottom>
                Pour créer l’histoire la plus passionante pour votre enfant, indiquez-nous quels sont ses jeux et ses univers favoris.
              </Typography>
              <Typography variant="body2" gutterBottom>
                N’hésitez pas à ajouter tous ses univers, c’est souvent ce qui donne les résultats les plus amusants et créatifs !
              </Typography>
              {errors?.selectedThemes ?
                <Typography variant="body2" color="orange" gutterBottom>
                  {errors.selectedThemes.message}
                </Typography>
                : <></>
              }
            </Grid>

            <Grid item xs={MOBILE_CONTENT_BREAKPOINT} md={DESKTOP_CONTENT_BREAKPOINT} display="flex" flexDirection="column" gap="20px">
              <Box
                display="flex"
                flexWrap="wrap"
                height="210px"
                overflow="scroll"
                gap="5px"
              >
                {Object.keys(THEMES).map(key => <Chip 
                  size="small" 
                  key={key}
                  label={THEMES[key]}
                  onChange={register('selectedThemes').onChange}
                  variant={isThemeSelected(key) ? "filled" : "outlined"}
                  color={isThemeSelected(key) ? "success" : "default"}
                  onClick={() => handleClickTheme(key)}
                  sx={{
                    ...isThemeSelected(key) && { backgroundImage: "linear-gradient(45deg, #12A2FE, #D865E7, #FA6C54, #FEA807)" }
                  }}
                />)}
              </Box>
              
              <Divider />
            </Grid>

            <Grid item xs={MOBILE_TITLE_BREAKPOINT} md={DESKTOP_TITLE_BREAKPOINT}>
              <Typography variant="h5" gutterBottom paddingBottom={2}>
                🎨 Style des illustrations
              </Typography>
              <Typography variant="body2" gutterBottom>
                Choisissez parmi les {STYLES.length} styles proposés pour illustrer votre livre.
              </Typography>
              {errors?.style ?
                <Typography variant="body2" color="orange" gutterBottom>
                  {errors.style.message}
                </Typography>
                : <></>
              }
            </Grid>
            
            <Grid item xs={MOBILE_CONTENT_BREAKPOINT} md={DESKTOP_CONTENT_BREAKPOINT} display="flex" flexDirection="column" gap="20px">
              <Grid 
                container 
                rowSpacing={1} 
              >
                {STYLES.map(style => 
                  <Grid item xs={6} padding={1}>
                    <Box onClick={() => handleClickStyle(style.id)} sx={{ cursor: 'pointer' }}>
                      <img 
                        src={style.image} 
                        alt={style.image} 
                        width="100%" 
                        style={ 
                          !isStyleSelected(style.id) 
                          ? { filter: 'grayscale(100%)', borderRadius: '10px' } 
                          : { borderRadius: '10px' } 
                        } 
                      />
                      <Typography align='center' variant="body2" gutterBottom>
                        {style.name}
                      </Typography>
                    </Box>
                  </Grid>
                )}
              </Grid>
            </Grid>
          </Grid>
        </form>
      </Box>
      <Box 
        sx={{ 
          position: "fixed",
          backgroundColor: "rgba(255, 255, 255, 0.5)", 
          backdropFilter: "blur(10px)",
          borderTop: "1px solid lightgray",
          bottom: "0px", 
          height: "64px", 
          width: "100%",
          zIndex: "100",
      }}>
        <Box
          display="flex"
          height="100%"
          flexDirection="row"
          paddingX="20px"
          alignItems="center"
          gap={3}
          justifyContent="flex-end"
        >
          {isLoading ? <CircularProgress size={20} /> : <></>}
          <Button variant="outlined" type="submit" onClick={handleSubmit(onSubmit)} disabled={isLoading || hasErrors}>
            Générer mon livre
          </Button>
        </Box>
      </Box>
    </>
  )
}